/* Button */
.btn {
  &:active,
  &:focus {
      box-shadow: none !important;
  }
}
.btn-primary,
.btn-dark {
  border: 0;
  border-radius: 3px;
  padding: 12px 20px 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-family: $font-secondary;
  transition: 350ms;
  font-size: 14px;
  @include tablet {
      font-size: 13px;
  }
}

.btn-white.btn-primary {
  background: #fff;
  color: #FFCE3B;
}

.btn-primary {
  background: #FFCE3B;
}

.btn-dark {
  background: #23282d;
}

.btn-primary:hover,
.btn-dark:hover,
.btn-white.btn-primary:hover {
  background: #111;
  color: #fff;
  &:active,
  &:focus {
      color: #fff;
      background-color: #111 !important;
  }
}

.general-btn {
  margin-top: 50px;
}